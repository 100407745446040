export default function Button({
  onClick,
  children,
  type = "button",
  disabled = false,
  classes,
  colors = "bg-purple text-white hover:bg-indigo-400 disabled:bg-indigo-200",
}: {
  children: React.ReactNode;
  onClick?: (e: React.FormEvent) => void;
  type?: "submit" | "button";
  disabled: boolean;
  colors?: string;
  classes?: string;
}) {
  let buttonClasses = `w-[140px] h-[50px] rounded-[10px] disabled:cursor-not-allowed ${colors}`;

  if (classes) {
    buttonClasses = classes;
  }

  return (
    <button
      onClick={onClick}
      type={type}
      className={buttonClasses}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
